.keyboard {
  display: flex;
  flex-direction: column;
  margin: 1rem 0;
}
.keyboard .letters1 {
  margin: 0.01rem 0;
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
}
.keyboard .letters2 {
  margin: 0.01rem 0;
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
}
.keyboard .letters3 {
  margin: 0.01rem 0;
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
}
.keyboard .letters3 + button {
  width: 20%;
  height: 1.5rem;
  margin: 1rem auto;
  font-size: medium;
}

.stage {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 0.5rem;
  padding: 0rem;
}
.stage label {
  font-weight: bold;
  margin-bottom: 0em;
  margin-top: 1em;
}
.stage svg {
  fill: none;
  stroke: var(--text-color);
}

.keyboard-button {
  font-size: 1.3rem;
  cursor: pointer;
  width: 1.5rem;
  padding: 0 0rem;
  height: 2.5rem;
  margin: 6px;
  outline: solid;
  font-weight: bold;
}

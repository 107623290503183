
ul, li, nav {  /* First reset margins and paddings for these elements for Firefox*/
  margin: 0;
  padding: 0;
}
.header {
  color: inherit;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; 
  margin-top: 0.5rem;
  width: 100vw; /* Set the width to the full viewport width */
  overflow: hidden; /* Prevent any overflow outside the viewport */
  top: 0; /* Position the top edge at the top of the viewport */
  left: 0; /* Align the left edge with the left side of the viewport */
}
.header nav {
  padding: 0.5rem;
}
.header nav ul {
  list-style: none; /* Remove default list styling */
}
.header nav ul li {
  align-items: center;
}
.header nav ul li label {
}
.header select,
.header option {
  text-transform: uppercase;
  font-size: 0.75rem;
} 
.header .textColorNUSblue {
  /* color: #033D7C; /* Replace "#colorhex" with your desired hex color code */
  /*display: inline;  /* This makes the text appear on the same line */
  margin: 0rem; /* Removes default margin */
  font-size: large;
  line-height: 1.3rem;
}.header .textColorNUSorange {
  color: #EF7C00; /* Replace "#colorhex" with your desired hex color code */
  /*display: inline; /* This makes the text appear on the same line */
  margin: 0rem; /* Removes default margin */
  background-color: transparent;
  font-size: large;
  line-height: 1.3rem;
}
.header .Subtitle{
  display: flex;
  font-size: x-small;
  text-align: center; /* Centers the subtitle text */
  justify-content: center;
  align-items: center;
  line-height: 10px;
}
/* Adding help popup */
.modal {
  /*display: none;*/ /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  left: 0;
  top: 0;
  width: 100vw; /* Full width */
  height: 100vh; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0,0,0); /* Fallback color */
  background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
}
.modal-content {
  /* background-color: #fefefe; */
  margin: 15% auto; /* 15% from the top and centered */
  padding: 20px;
  border: 1px solid #888;
  width: 80vw; /* Could be more or less, depending on screen size */
  font-size: small;
}
.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}
.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}
.help-toggle {
  margin-left: 0.5rem;
  cursor: pointer;
  display: flex;
  padding: 0.2rem;
  width: 2rem; /* Adjusted to give more space around the icon */
  height: 2rem; /* Adjusted to give more space around the icon */
  border-radius: 50%; /* Gives a rounded shape to the button */
  /*box-shadow: 4px 4px 8px #a3b1c6, -4px -4px 8px #ffffff; /* Neumorphic effect */
  transition: background-color 0.3s; /* Smooth transition for background color change */
}
.transparent-icon {
  background-color: transparent;
}
.center-divider {
    width: 60vw; /* Adjust width as needed */
    margin: 0 auto; /* Centers the divider horizontally */
    height: 2px; /* Height of the divider */
    background-color:  #EF7C00; /* Color of the divider */
    margin-top: 1rem;
    margin-bottom: 1rem;
}
/*a[href="https://en.wikipedia.org/wiki/Hangman_(game)"]:visited,
a[href="https://en.wikipedia.org/wiki/Hangman_(game)"]:hover,
a[href="https://en.wikipedia.org/wiki/Hangman_(game)"]:active {
  color: #EF7C00; /* Orange color for all link states */
/*}*/
/* Ensure responsiveness and visibility at all viewport sizes */
@media (max-width: 600px) {
  nav ul {
    flex-direction: column; /* Stack navigation items vertically on small screens */
  }
}



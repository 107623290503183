
.guessword {
  display: flex;
  flex-wrap: nowrap;
  margin-top: 0.5rem;      /* top margin */
  margin-right: 0rem;    /* right margin */
  margin-bottom: 1rem;   /* increased bottom margin */
  margin-left: 0rem;     /* left margin */
  max-width: 100%; /* Ensures the container doesn't overflow */
  justify-content: center; /* This centers the letters in the guessword div */
}

/*.guess-letter {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;
  font-size: 1.3rem;
  font-weight: bold;
  padding: 0.1rem 0.1rem;
  width: 1rem;
  height: 2rem;
  margin: 2px;
}*/
:root {
  --dynamic-width: 100px; /* default width */
}

.guess-letter {
  width: var(--dynamic-width);
/*  width: calc(100vw / 15); /* Ensures each letter takes up 1/15th of the total width */
  display: inline-flex;
  justify-content: center; /* Centers the content horizontally within each letter box */
  align-items: center; /* Centers the content vertically within each letter box */
  margin: 0 2px; /* Adjust spacing between letters */
  /*padding: 8px 0; /* Top and bottom padding */
  display: flex;
  flex-wrap: wrap;
  /*justify-content: center;*/
  align-content: center;
  font-size: 1.3rem;
  font-weight: bold;
  padding: 0.1rem 0.1rem;
 /* width: 1rem; */
  height: 3rem;
  margin: 2px;
}

/*.neumorph {
  /* Assuming neumorph styles are defined elsewhere in the CSS */
 /* box-shadow: inset 2px 2px 5px #babecc,
              inset -5px -5px 10px #ffffff;
  border-radius: 5px;
} */

/*.invert.disabled {
  /* Further styles for the disabled state */
  /*background-color: #e0e5ec; /* Light grey background */
  /*color: transparent; /* Makes the dash '-' transparent when not guessed */
/*}*/



/*custom css*/
/*another neumorphics design https://codepen.io/ryanparag/pen/OJVJrxZ */

:root {
  --error-color: #f94144;
  --success-color: #2a9d8f;
  --text-color: #003D7C;
  --link-active-color: #EF7C00;
  --gradient-start-color: #bd4a8e;
  --gradient-end-color: #e95c7c;
  --gradient: linear-gradient(
    0,
    var(--gradient-start-color),
    var(--gradient-end-color)
  );
  --background: #ffffff;
  --highlight: #ffffff;
  --shadow: #cbd2d8;
  /* --neumorph-shadow: 4px 4px 8px rgba(0, 0, 0, 0.2), -4px -4px 8px #fff;
  --neumorph-shadow-inset: inset 4px 4px 8px rgba(0, 0, 0, 0.2),
    inset -4px -4px 8px #fff; */

  --neumorph-shadow: 4px 4px 8px var(--shadow), -4px -4px 8px var(--highlight),
    -2px -2px 4px var(--highlight);

  --neumorph-shadow-inset: inset 2px 2px 4px var(--shadow),
    inset -2px -2px 4px var(--highlight), inset -2px -2px 2px var(--shadow),
    inset -1px -1px 1px var(--highlight), -1px -1px 3px var(--highlight),
    -1px -1px 4px var(--shadow), 1px 1px 1px var(--highlight),
    2px 2px 4px var(--shadow);

  --neumorph-hover-bg: #ef7b008e;
}

/* dark theme */
.dark {
  --text-color: #ffffff;
  /* --link-active-color: #e95c7c; */
  --gradient-start-color: #bd4a8e;
  --gradient-end-color: #e95c7c;
  --background: #222222;
  --highlight: rgb(53, 52, 52);
  --shadow: rgb(19, 19, 19);
  /* --neumorph-shadow: 4px 4px 8px 0 rgba(0, 0, 0, 0.8),
    -4px -4px 8px 0 rgba(255, 255, 255, 0.1);
  --neumorph-shadow-inset: inset 4px 4px 8px 0 rgba(0, 0, 0, 0.8),
    inset -4px -4px 8px 0 rgba(255, 255, 255, 0.1);
  --neumorph-text-shadow: 0px 0px 4px rgba(255, 255, 255, 0.5); */
  --neumorph-shadow: 4px 4px 8px var(--shadow), -4px -4px 8px var(--highlight),
    -2px -2px 4px var(--highlight);

  --neumorph-shadow-inset: inset 4px 4px 8px var(--shadow),
    inset -4px -4px 8px var(--highlight), inset -4px -4px 4px var(--shadow),
    inset -2px -2px 2px var(--highlight), -2px -2px 6px var(--highlight),
    -2px -2px 8px var(--shadow), 2px 2px 2px var(--highlight),
    4px 4px 8px var(--shadow);
  --neumorph-hover-bg: #003D7C;
}
.app {
  min-height: 100vh;
}
body,
* {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Poppins", "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: var(--background);
  color: var(--text-color);
}
a:visited,
a:active {
  color: var(--link-active-color) !important;
}
input,
select {
  padding: 0.1rem;
  color: var(--text-color);
  font-weight: bold;
  height: 1.7rem;
  width: 12rem;
  outline: 0;
}

.neumorph {
  background: transparent;
  color: var(--text-color);
  border: none;
  border-radius: 3px;
  box-shadow: var(--neumorph-shadow);
  transition: color background-color box-shadow 100ms ease-in;
}
.neumorph.disabled {
  color: var(--link-active-color);
}
.neumorph.invert {
  box-shadow: var(--neumorph-shadow-inset);
}
.neumorph:not(.disabled):hover {
  /*color: var(--link-active-color);*/
  background: var(--neumorph-hover-bg);
}

.neumorph:not(.disabled):active {
  box-shadow: var(--neumorph-shadow-inset);
}
.neumorph.invert:not(.disabled):active {
  box-shadow: var(--neumorph-shadow);
}

/* main container */
html, body {
  height: 100vh;
  margin: 0;
  overflow: hidden; /* Prevent scrolling */
}
.container {
  height: 100vh;
  display: flex;
  flex-direction: column;
  width: 100vw;
  margin: 0 auto;
}
/* rest the box sizing at the start for Firefox compatibility */
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}


/* 
.Hangman img {
  width: 200px;
  height: 200px;
}

.Hangman h1 {
  font-size: 3rem;
  font-weight: 400;
  padding-top: 0;
  color: var(--secondary-color);
}

.Hangman-word {
  letter-spacing: 1em;
  margin: 0.4em -1em 0.2em 0;
  font-size:10rem;
  color: var(--main-color);
} */

.result {
  padding: 0.6rem;
  font-size: 1rem;
  font-weight: bold;
  min-height: 3rem; /* Adjust the height as needed */
  align-content: center;
}
.error {
  color: var(--error-color) !important;
}
.success {
  color: var(--success-color) !important;
}
.null {
  color: var(--link-active-color) !important;
}